$content_height: calc(100vh - 285px);

.view-media-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #000000d9;

  .view-media-header {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    height: 100px;

    .view-media-header-title {
      text-align: center;
      align-self: center;
      color: #cccccc;
      font-size: 2em;
    }

    .view-media-header-actions {
      align-self: center;
      color: #cccccc;
      direction: rtl;
      padding-right: 36px;

      & > button {
        background: unset;
        border: unset;
        padding: 0 16px;
      }
    }
  }

  .view-media-content-container {
    height: $content_height;
    // hide default video player styles
    background: linear-gradient(0deg, black 0%, black 1%, rgba(0, 0, 0, 0) 54%);
    display: grid;
    grid-template-columns: 75px auto 75px;

    & > button {
      background: unset;
      border: unset;
      color: #cccccc;

      &:hover {
        background: #0000003d;
        opacity: 0.9;
      }
    }

    .view-media-content {
      max-height: $content_height;
  
      .view-media-photo-container {
        width: min-content;
        margin: 0 auto;        
        height: 100%;

        & > img {
          max-width: calc(100vw - 160px);
          min-width: 100%;
          max-height: $content_height;
        }
      }
    }
  }

  .view-media-items {
    background-color: black;
    height: 185px;

    & > div {
      height: 100%;
      align-content: center;
    }
  }
}